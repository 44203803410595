// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-newsletter-archiv-js": () => import("./../src/pages/aktuelles/newsletter/archiv.js" /* webpackChunkName: "component---src-pages-aktuelles-newsletter-archiv-js" */),
  "component---src-pages-aktuelles-newsletter-js": () => import("./../src/pages/aktuelles/newsletter.js" /* webpackChunkName: "component---src-pages-aktuelles-newsletter-js" */),
  "component---src-pages-aktuelles-newsroom-js": () => import("./../src/pages/aktuelles/newsroom.js" /* webpackChunkName: "component---src-pages-aktuelles-newsroom-js" */),
  "component---src-pages-aktuelles-ratgeber-arbeitgeberfinanzierte-altersvorsorge-js": () => import("./../src/pages/aktuelles/ratgeber/arbeitgeberfinanzierte-altersvorsorge.js" /* webpackChunkName: "component---src-pages-aktuelles-ratgeber-arbeitgeberfinanzierte-altersvorsorge-js" */),
  "component---src-pages-aktuelles-ratgeber-berufsunfaehigkeit-js": () => import("./../src/pages/aktuelles/ratgeber/berufsunfaehigkeit.js" /* webpackChunkName: "component---src-pages-aktuelles-ratgeber-berufsunfaehigkeit-js" */),
  "component---src-pages-aktuelles-ratgeber-betriebliche-altersvorsorge-js": () => import("./../src/pages/aktuelles/ratgeber/betriebliche-altersvorsorge.js" /* webpackChunkName: "component---src-pages-aktuelles-ratgeber-betriebliche-altersvorsorge-js" */),
  "component---src-pages-aktuelles-ratgeber-js": () => import("./../src/pages/aktuelles/ratgeber.js" /* webpackChunkName: "component---src-pages-aktuelles-ratgeber-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mediathek-js": () => import("./../src/pages/mediathek.js" /* webpackChunkName: "component---src-pages-mediathek-js" */),
  "component---src-pages-tools-und-services-js": () => import("./../src/pages/tools-und-services.js" /* webpackChunkName: "component---src-pages-tools-und-services-js" */),
  "component---src-pages-unsere-dienstleistungen-bav-beratung-und-betreuung-betriebsrenten-portal-js": () => import("./../src/pages/unsere-dienstleistungen/bav-beratung-und-betreuung/betriebsrenten-portal.js" /* webpackChunkName: "component---src-pages-unsere-dienstleistungen-bav-beratung-und-betreuung-betriebsrenten-portal-js" */),
  "component---src-pages-unsere-dienstleistungen-bav-beratung-und-betreuung-js": () => import("./../src/pages/unsere-dienstleistungen/bav-beratung-und-betreuung.js" /* webpackChunkName: "component---src-pages-unsere-dienstleistungen-bav-beratung-und-betreuung-js" */),
  "component---src-pages-unsere-dienstleistungen-bav-beratung-und-betreuung-xbav-und-epension-js": () => import("./../src/pages/unsere-dienstleistungen/bav-beratung-und-betreuung/xbav-und-epension.js" /* webpackChunkName: "component---src-pages-unsere-dienstleistungen-bav-beratung-und-betreuung-xbav-und-epension-js" */),
  "component---src-pages-unsere-dienstleistungen-personaldienstleistungen-js": () => import("./../src/pages/unsere-dienstleistungen/personaldienstleistungen.js" /* webpackChunkName: "component---src-pages-unsere-dienstleistungen-personaldienstleistungen-js" */),
  "component---src-pages-unsere-dienstleistungen-versicherungsmathematische-dienstleistungen-js": () => import("./../src/pages/unsere-dienstleistungen/versicherungsmathematische-dienstleistungen.js" /* webpackChunkName: "component---src-pages-unsere-dienstleistungen-versicherungsmathematische-dienstleistungen-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-leitende-angestellte-entgeltumwandlung-laufende-beitraege-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-leitende-angestellte/entgeltumwandlung-laufende-beitraege.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-leitende-angestellte-entgeltumwandlung-laufende-beitraege-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-leitende-angestellte-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-leitende-angestellte.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-leitende-angestellte-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-bav-direktversicherung-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-direktversicherung.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-bav-direktversicherung-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-bav-pensionsfonds-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-pensionsfonds.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-bav-pensionsfonds-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-bav-pensionszusage-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-pensionszusage.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-bav-pensionszusage-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-bav-unterstuetzungskasse-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-unterstuetzungskasse.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-bav-unterstuetzungskasse-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-altersvorsorge-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-krankenversicherung-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-krankenversicherung.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betriebliche-krankenversicherung-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betrieblicher-einkommensschutz-eks-berufsunfaehigkeit-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/eks-berufsunfaehigkeit.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betrieblicher-einkommensschutz-eks-berufsunfaehigkeit-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betrieblicher-einkommensschutz-eks-grundfaehigkeitsversicherung-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/eks-grundfaehigkeitsversicherung.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betrieblicher-einkommensschutz-eks-grundfaehigkeitsversicherung-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betrieblicher-einkommensschutz-gfv-highlights-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/gfv-highlights.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betrieblicher-einkommensschutz-gfv-highlights-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betrieblicher-einkommensschutz-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-betrieblicher-einkommensschutz-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-weitere-absicherungsmoeglichkeiten-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-mitarbeiter/weitere-absicherungsmoeglichkeiten.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-mitarbeiter-weitere-absicherungsmoeglichkeiten-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-unternehmen-absicherung-ihres-unternehmens-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-unternehmen/absicherung-ihres-unternehmens.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-unternehmen-absicherung-ihres-unternehmens-js" */),
  "component---src-pages-unsere-loesungen-absicherung-fuer-unternehmen-js": () => import("./../src/pages/unsere-loesungen/absicherung-fuer-unternehmen.js" /* webpackChunkName: "component---src-pages-unsere-loesungen-absicherung-fuer-unternehmen-js" */),
  "component---src-pages-wir-ueber-uns-js": () => import("./../src/pages/wir-ueber-uns.js" /* webpackChunkName: "component---src-pages-wir-ueber-uns-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-media-category-page-js": () => import("./../src/templates/media-category-page.js" /* webpackChunkName: "component---src-templates-media-category-page-js" */)
}


/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'src/styles/global.scss';

export const onClientEntry = () => {
  /**
   * IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
   */
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer'); // eslint-disable-line

    console.log('IntersectionObserver is polyfilled!');
  }
};
